<template>
    <v-card elevation="0">
        <v-system-bar
            @click="clickToggle"
            dark
            :color="active ? 'primary darken-2' : '#EEEEEE'"
            height="40"
        >
            <v-chip
                :color="active ? 'white' : 'primary darken-2'"
                v-if="surveyQuestion.order"
                outlined
                >{{ surveyQuestion.order }}</v-chip
            >

            <v-spacer></v-spacer>
            <v-btn
                v-if="surveyQuestion.surveyQuestionGuid != null"
                icon
                @click="moveCardUp(surveyQuestion)"
            >
                <v-icon :color="active ? 'white' : 'primary darken-2'"
                    >mdi-arrow-up-thin</v-icon
                >
            </v-btn>
            <v-btn
                v-if="surveyQuestion.surveyQuestionGuid != null"
                icon
                @click="moveCardDown(surveyQuestion)"
            >
                <v-icon :color="active ? 'white' : 'primary darken-2'"
                    >mdi-arrow-down-thin</v-icon
                >
            </v-btn>
        </v-system-bar>
        <v-card-text>
            <template v-if="surveyQuestion.questionType == 1">
                <Text-Question :question="surveyQuestion" />
            </template>
        </v-card-text>
        <v-card-actions>
            <v-row>
                <v-col cols="auto">
                    <v-btn @click="saveQuestion" icon>
                        <v-icon color="primary"
                            >mdi-content-save-outline</v-icon
                        >
                    </v-btn></v-col
                >
                <v-col cols="auto">
                    <v-btn @click="openDeleteDialog" class="mx-2" icon>
                        <v-icon color="red">mdi-delete-outline</v-icon>
                    </v-btn></v-col
                >
            </v-row>
        </v-card-actions>
        <confirm-dialog
            :openDialog="deleteDialog"
            :onClicked="deleteConfirm"
            :onClose="closeDeleteDialog"
            toolBarColor="red darken-2"
        ></confirm-dialog>
    </v-card>
</template>
<script>
import TextQuestion from "./TextQuestion.vue";
import ConfirmDialog from "../../components/ConfirmDialog.vue";
import SurveysApi from "../../Api/SurveysApi";

export default {
    components: { TextQuestion, ConfirmDialog },
    props: [
        "surveyQuestion",
        "moveCardUp",
        "moveCardDown",
        "refreshQuestions",
        "clickToggle",
        "surveyGuid",
        "removeQuestion",
        "getQuestionOrder",
        "active"
    ],
    data() {
        return {
            deleteDialog: false
        };
    },
    methods: {
        openDeleteDialog() {
            if (this.surveyQuestion.surveyQuestionGuid == null)
                this.removeQuestion(this.surveyQuestion);
            else this.deleteDialog = true;
        },
        deleteConfirm() {
            this.removeQuestion(this.surveyQuestion);
        },
        closeDeleteDialog() {
            this.deleteDialog = false;
        },
        saveQuestion() {
            try {
                if (this.surveyGuid == null) return;

                var question = Object.assign({}, this.surveyQuestion);

                if (
                    question.questionText == null ||
                    question.questionText == ""
                )
                    return;

                question.order = this.getQuestionOrder(this.surveyQuestion);
                question.surveyGuid = this.surveyGuid;

                if (question.surveyQuestionGuid == null) {
                    SurveysApi.createQuestion(question)
                        .then(response => {
                            if (
                                response.data.status == this.responseStatus.ok
                            ) {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.refreshQuestions();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        })
                        .finally(() => {
                            this.dialogLoading = false;
                        });
                } else {
                    SurveysApi.updateQuestion(question)
                        .then(response => {
                            if (
                                response.data.status == this.responseStatus.ok
                            ) {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.refreshQuestions();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        })
                        .finally(() => {
                            this.dialogLoading = false;
                        });
                }
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
