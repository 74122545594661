import axios from "axios";

export default class SurveysApi {
    static getSurveys(id = null) {
        if (id != null)
            return axios.get("Surveys/Get?id=" + id).then(response => response);
        return axios.get("Surveys/Get").then(response => response);
    }

    static createSurvey = survey => {
        return axios.post("Surveys/Create", survey).then(response => response);
    };

    static updateSurvey = survey => {
        return axios.put("Surveys/Update", survey).then(response => response);
    };

    static deleteSurvey = surveyGuid => {
        return axios
            .delete("Surveys/Delete?guid=" + surveyGuid)
            .then(response => response);
    };

    static changeActivation = surveyGuid => {
        return axios
            .put("Surveys/ChangeActivation?guid=" + surveyGuid)
            .then(response => response);
    };

    static createQuestion = question => {
        return axios
            .post("Surveys/CreateQuestion", question)
            .then(response => response);
    };

    static updateQuestion = question => {
        return axios
            .put("Surveys/UpdateQuestion", question)
            .then(response => response);
    };

    static deleteQuestion = questionGuid => {
        return axios
            .delete("Surveys/DeleteQuestion?guid=" + questionGuid)
            .then(response => response);
    };

    static getQuestions = surveyGuid => {
        return axios
            .get("Surveys/GetSurveyQuestions?id=" + surveyGuid)
            .then(response => response);
    };

    static reorderQuestions = questions => {
        return axios
            .put("Surveys/ReorderQuestions", questions)
            .then(response => response);
    };
}
