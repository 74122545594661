<template>
    <!-- :color="$vuetify.theme.dark ? '#272727' : '#f5f5f5'" -->
    <v-toolbar
        elevation="0"
        :color="$vuetify.theme.dark ? '#212121' : '#EEEEEE'"
        :class="$vuetify.theme.dark ? 'white--text' : 'black--text'"
    >
        <v-toolbar-title>
            <v-icon>{{ icon }}</v-icon>
            {{ title }}</v-toolbar-title
        >
        <v-spacer></v-spacer>
        <slot name="buttons"></slot>
        <!-- 252e38 -->
    </v-toolbar>
</template>

<script>
export default {
    props: ["title", "icon"]
};
</script>

<style></style>
