<template>
    <v-card elevation="0">
        <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
                <v-row justify="center">
                    <v-col cols="12">
                        <v-text-field
                            dir="auto"
                            dense
                            outlined
                            :rules="rules"
                            v-model="question.questionText"
                            :label="$t('surveys.questions.text')"
                            hide-details
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12">
                        <v-text-field
                            dir="auto"
                            hide-details
                            outlined
                            dense
                            v-model="question.questionHint"
                            :label="$t('surveys.questions.hint')"
                        >
                        </v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4">
                        <v-checkbox
                            hide-details
                            dense
                            v-model="question.isRequired"
                            :label="$t('surveys.questions.required')"
                        >
                        </v-checkbox>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    props: ["question"],
    data() {
        return {
            valid: true,
            rules: [value => !!value || this.$t("required")]
        };
    }
};
</script>
