<template>
    <v-card elevation="0" id="create">
        <v-row class="pt-8" justify="center">
            <v-btn
                v-if="surveyGuid != null && questions.length != 0"
                @click="reorderQuestions"
                outlined
                color="primary"
                :disabled="!canAddQuestion"
                ><v-icon>mdi-refresh</v-icon
                >{{ $t("surveys.ui.reorderQuestions") }}</v-btn
            >
        </v-row>
        <v-speed-dial
            v-if="surveyGuid != null"
            v-model="fab"
            bottom
            fixed
            class="ma-5 d-none d-lg-flex"
            direction="top"
            open-on-hover
            transition="slide-y-reverse-transition"
        >
            <template v-slot:activator>
                <v-btn v-model="fab" color="primary darken-2" fab>
                    <v-icon color="white">
                        mdi-plus
                    </v-icon>
                </v-btn>
            </template>
            <v-btn
                :disabled="unsavedQuestion"
                @click="addTextQuestion"
                fab
                small
                color="#3949AB"
            >
                <v-icon color="white">mdi-format-text</v-icon>
            </v-btn>
            <v-btn :disabled="unsavedQuestion" fab small color="#039BE5">
                <v-icon color="white">mdi-radiobox-marked</v-icon>
            </v-btn>
            <v-btn :disabled="unsavedQuestion" fab small color="#00897B">
                <v-icon color="white">mdi-order-bool-ascending-variant</v-icon>
            </v-btn>
        </v-speed-dial>
        <v-item-group class="my-5" active-class="primary">
            <v-row justify="center">
                <v-col
                    v-for="(q, i) in questions"
                    :key="i"
                    cols="10"
                    lg="8"
                    md="10"
                >
                    <v-item v-slot="{ active, toggle }">
                        <v-card>
                            <Question-Container
                                :clickToggle="toggle"
                                :surveyQuestion="q"
                                :surveyGuid="surveyGuid"
                                :removeQuestion="removeQuestion"
                                :getQuestionOrder="getQuestionOrder"
                                :refreshQuestions="refreshQuestions"
                                :moveCardDown="moveCardDown"
                                :moveCardUp="moveCardUp"
                                :active="active"
                            />
                        </v-card>
                    </v-item>
                </v-col>
            </v-row>
        </v-item-group>
        <v-row v-if="surveyGuid != null" class="my-5" justify="center">
            <v-btn
                :disabled="unsavedQuestion"
                class="d-lg-none d-xl-none mx-2"
                @click="addTextQuestion"
                fab
                small
                color="#3949AB"
            >
                <v-icon color="white">mdi-format-text</v-icon>
            </v-btn>
            <v-btn
                :disabled="unsavedQuestion"
                class="d-lg-none d-xl-none mx-2"
                fab
                small
                color="#039BE5"
            >
                <v-icon color="white">mdi-radiobox-marked</v-icon>
            </v-btn>
            <v-btn
                :disabled="unsavedQuestion"
                class="d-lg-none d-xl-none mx-2"
                fab
                small
                color="#00897B"
            >
                <v-icon color="white">mdi-order-bool-ascending-variant</v-icon>
            </v-btn>
        </v-row>
    </v-card>
</template>
<script>
import QuestionContainer from "./QuestionContainer.vue";
import SurveysApi from "../../Api/SurveysApi";

export default {
    components: { QuestionContainer },
    props: {
        surveyQuestions: {
            type: Array,
            default: new Array()
        },
        surveyGuid: {
            type: String
        }
    },
    created() {
        //if (this.surveyQuestions != undefined && this.surveyQuestions != null)
        this.questions = this.surveyQuestions;
    },
    data() {
        return {
            unsavedQuestion: false,
            canAddQuestion: true,
            fab: true,
            questions: []
        };
    },
    methods: {
        addTextQuestion() {
            this.questions.push({
                questionText: null,
                questionHint: null,
                questionType: 1,
                isRequired: true
            });
            this.scrollToBottom();
            this.canAddQuestion = false;
            this.unsavedQuestion = true;
        },
        moveCardUp(question) {
            var toIndex = 0;
            var fromIndex = this.questions.indexOf(question);
            if (fromIndex != 0) toIndex = fromIndex - 1;
            const element = this.questions.splice(fromIndex, 1)[0];
            this.questions.splice(toIndex, 0, element);
        },
        moveCardDown(question) {
            var fromIndex = this.questions.indexOf(question);
            var toIndex = this.questions.length - 1;
            if (fromIndex != this.questions.length - 1) toIndex = fromIndex + 1;
            const element = this.questions.splice(fromIndex, 1)[0];
            this.questions.splice(toIndex, 0, element);
        },
        removeQuestion(question) {
            try {
                if (question.surveyQuestionGuid == null) {
                    var questionIndex = this.questions.indexOf(question);
                    this.questions.splice(questionIndex, 1);
                    return;
                } else {
                    SurveysApi.deleteQuestion(question.surveyQuestionGuid)
                        .then(response => {
                            if (
                                response.data.status == this.responseStatus.ok
                            ) {
                                this.$toast.success(
                                    this.$t("operationAccomplishedSuccessfully")
                                );
                                this.refreshQuestions();
                            } else {
                                this.$toast.error(
                                    this.$t("error." + response.data.message)
                                );
                            }
                        })
                        .catch(e => {
                            this.$toast.error(
                                this.$t("AnErrorOccurredDuringTheProcess")
                            );
                            console.log(e);
                        });
                }
            } catch (error) {
                console.log(error);
            }
        },
        getQuestionOrder(question) {
            return this.questions.indexOf(question) + 1;
        },
        refreshQuestions() {
            SurveysApi.getQuestions(this.surveyGuid)
                .then(response => {
                    this.questions = response.data.data;
                    this.canAddQuestion = true;
                    this.unsavedQuestion = false;
                })
                .catch(e => {
                    this.$toast.error(
                        this.$t("AnErrorOccurredDuringTheProcess")
                    );
                    console.log(e);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        scrollToBottom() {
            this.$vuetify.goTo(document.body.scrollHeight);
        },
        reorderQuestions() {
            try {
                for (let i = 0; i < this.questions.length; i++) {
                    if (
                        this.questions[i].surveyQuestionGuid == undefined ||
                        this.questions[i].surveyQuestionGuid == null
                    )
                        this.$toast.warning(
                            this.$t(
                                "surveys.warnings.cannotReorderUnsavedQuestions"
                            )
                        );
                    this.questions[i].order = i + 1;
                }

                var questionsToOrder = this.questions.map(
                    ({ surveyQuestionGuid, order }) => ({
                        surveyQuestionGuid,
                        order
                    })
                );

                SurveysApi.reorderQuestions(questionsToOrder)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                            this.refreshQuestions();
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.dialogLoading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        }
    }
};
</script>
<style scoped>
/* .v-sheet.v-card {
    border-color: #2f968887;
    border-width: 2px;
} */

#create .v-btn--floating {
    position: relative;
}
</style>
