<template>
    <v-container fluid>
        <v-card :loading="loading">
            <Page-Header
                :title="$t('surveys.survey') + ' / ' + survey.title"
                icon="mdi-file-document-edit-outline"
            >
            </Page-Header>
            <v-tabs v-model="tabs" centered icons-and-text>
                <v-tab>
                    {{ $t("surveys.ui.surveyTab") }}
                    <v-icon>mdi-file-document-edit-outline</v-icon>
                </v-tab>
                <v-tab>
                    {{ $t("surveys.ui.surveyQuestions") }}
                    <v-icon>mdi-chat-question-outline</v-icon>
                </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tabs">
                <v-tab-item>
                    <v-card flat>
                        <v-card-text>
                            <v-form ref="form" v-model="valid" lazy-validation>
                                <v-row justify="center">
                                    <v-col cols="8"
                                        ><v-text-field
                                            dir="auto"
                                            dense
                                            outlined
                                            v-model="survey.title"
                                            hide-details
                                            :rules="rules"
                                            :label="$t('surveys.title')"
                                            required
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="12" md="4">
                                        <v-menu
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    outlined
                                                    hide-details
                                                    :rules="rules"
                                                    dense
                                                    v-model="survey.startDate"
                                                    :label="
                                                        $t('surveys.startDate')
                                                    "
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="survey.startDate"
                                                @input="menu2 = false"
                                            ></v-date-picker> </v-menu
                                    ></v-col>
                                    <v-col cols="12" md="4"
                                        ><v-menu
                                            v-model="menu1"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    outlined
                                                    dense
                                                    :rules="rules"
                                                    hide-details
                                                    v-model="survey.endDate"
                                                    :label="
                                                        $t('surveys.endDate')
                                                    "
                                                    readonly
                                                    v-bind="attrs"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="survey.endDate"
                                                @input="menu1 = false"
                                            ></v-date-picker> </v-menu
                                    ></v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="8">
                                        <v-textarea
                                            v-model="survey.description"
                                            outlined
                                            hide-details
                                            dense
                                            rows="2"
                                            :label="$t('surveys.description')"
                                        ></v-textarea
                                    ></v-col>
                                </v-row>
                                <v-row justify="center">
                                    <v-col cols="auto">
                                        <v-checkbox
                                            hide-details
                                            class="pt-0"
                                            v-model="survey.isActive"
                                            :label="$t('surveys.isActive')"
                                        ></v-checkbox>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-checkbox
                                            hide-details
                                            class="pt-0"
                                            v-model="survey.hasResultPublished"
                                            :label="
                                                $t('surveys.hasResultPublished')
                                            "
                                        ></v-checkbox>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                :disabled="!valid"
                                color="primary darken-1"
                                @click="save"
                            >
                                <v-icon>mdi-content-save-outline</v-icon>
                                {{ $t("save") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-tab-item>
                <v-tab-item>
                    <Survey-Questions
                        :surveyQuestions="survey.questions"
                        :surveyGuid="survey.surveyGuid"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-card>
    </v-container>
</template>
<script>
import SurveysApi from "../../Api/SurveysApi";
import PageHeader from "../../components/PageHeader.vue";
import moment from "moment";
import SurveyQuestions from "./SurveyQuestions.vue";

export default {
    components: { PageHeader, SurveyQuestions },
    data() {
        return {
            questions: [],
            forceWrite: false,
            tabs: null,
            menu2: false,
            menu1: false,
            valid: true,
            loading: true,
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            survey: {
                surveyGuid: null,
                title: "",
                description: null,
                startDate: null,
                endDate: null,
                isActive: false,
                activationDate: null,
                hasResultPublished: false,
                resultPublishDate: null,
                questions: []
            },
            editedItem: {},
            defaultItem: {
                surveyGuid: null,
                title: "",
                description: null,
                startDate: null,
                endDate: null,
                isActive: false,
                activationDate: null,
                hasResultPublished: false,
                resultPublishDate: null
            },
            rules: [value => !!value || this.$t("required")]
        };
    },
    created() {
        if (!this.isInRole(0)) this.redirectUnauthorizedUsers();

        var surveyGuid = this.getSurveyGuidFromRoute();
        this.getSurveyInfo(surveyGuid);
    },
    methods: {
        getSurveyInfo(surveyGuid) {
            try {
                this.loading = true;

                if (surveyGuid == null) {
                    this.loading = false;
                    return null;
                }

                SurveysApi.getSurveys(surveyGuid)
                    .then(response => {
                        this.survey = response.data.data;
                        this.survey.startDate = moment(
                            this.survey.startDate
                        ).format("YYYY-MM-DD");

                        this.survey.endDate = moment(
                            this.survey.endDate
                        ).format("YYYY-MM-DD");

                        if (this.survey.activationDate != null)
                            this.survey.activationDate = moment(
                                this.survey.activationDate
                            ).format("YYYY-MM-DD");

                        if (this.survey.resultPublishDate != null)
                            this.survey.resultPublishDate = moment(
                                this.survey.resultPublishDate
                            ).format("YYYY-MM-DD");

                        if (
                            this.survey.questions == undefined ||
                            this.survey.questions == null
                        )
                            this.survey.questions = [];
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } catch (error) {
                console.log(error);
            }
        },
        save() {
            if (!this.$refs.form.validate()) return;

            if (this.survey.surveyGuid == null)
                SurveysApi.createSurvey(this.survey)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );

                            this.$router.push({
                                name: "surveys.survey",
                                params: {
                                    id: response.data.data.surveyGuid
                                }
                            });
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            else
                SurveysApi.updateSurvey(this.survey)
                    .then(response => {
                        if (response.data.status == this.responseStatus.ok) {
                            this.$toast.success(
                                this.$t("operationAccomplishedSuccessfully")
                            );
                        } else {
                            this.$toast.error(
                                this.$t("error." + response.data.message)
                            );
                        }
                    })
                    .catch(e => {
                        this.$toast.error(
                            this.$t("AnErrorOccurredDuringTheProcess")
                        );
                        console.log(e);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
        },
        getSurveyGuidFromRoute() {
            if (this.$route.params.id != undefined)
                return this.$route.params.id;
            else return null;
        }
    }
};
</script>
